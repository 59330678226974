import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=34c0b833&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/codebuild/output/src3687748429/src/eddys/components/global/Footer.vue').default})
