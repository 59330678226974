import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=b390db60&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/codebuild/output/src3687748429/src/eddys/components/global/Loader.vue').default})
