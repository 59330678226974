import { render, staticRenderFns } from "./index.vue?vue&type=template&id=05227624&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuSpacer: require('/codebuild/output/src3687748429/src/eddys/components/MenuSpacer.vue').default,SectionHero: require('/codebuild/output/src3687748429/src/eddys/components/section/Hero.vue').default,GalleryModal: require('/codebuild/output/src3687748429/src/eddys/components/GalleryModal.vue').default,SectionFood: require('/codebuild/output/src3687748429/src/eddys/components/section/Food.vue').default,SectionCateringAndLocations: require('/codebuild/output/src3687748429/src/eddys/components/section/CateringAndLocations.vue').default,SectionAboutAndEmployment: require('/codebuild/output/src3687748429/src/eddys/components/section/AboutAndEmployment.vue').default,Footer: require('/codebuild/output/src3687748429/src/eddys/components/global/Footer.vue').default,SmoothScroll: require('/codebuild/output/src3687748429/src/eddys/components/global/SmoothScroll.vue').default,SEO: require('/codebuild/output/src3687748429/src/eddys/components/global/SEO.vue').default,PreviewModeWrapper: require('/codebuild/output/src3687748429/src/eddys/components/global/PreviewModeWrapper.vue').default})
